@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@font-face {
  font-family: 'TTOctosquaresComp';
  src: url("fonts/octosquares/TTOctosquaresComp-Light.otf");
}

@layer base {
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    opacity: 1;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
    border-radius: 10px;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 2px rgba(0,0,0,.3);
    background: rgb(210, 210, 210);
  }
}

@layer components {
  .b2b-trailing-text-overlay {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    animation: hideOverlay 4.5s ease-in-out;
  }
  
  @keyframes hideOverlay {
    0% {
      z-index: auto;
      opacity: 1;
      visibility: visible;
    }
  
    95% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      z-index: -1;
      visibility: hidden;
    }
  }
  
  .b2b-trailing-text-container {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    animation: hideContainer 4s ease-in-out;
  }
  
  @keyframes hideContainer {
    0% {
      z-index: auto;
      opacity: 1;
      visibility: visible;
    }
  
    75% {
      opacity: 1;
    }
  
    90% {
      opacity: 0;
    }
  
    100% {
      z-index: -1;
      visibility: hidden;
    }
  }
  
  .b2b-trailing-text-container > p {
    text-shadow: 0 0 5px #fff,0 0 10px #fff;
    font-size: theme('fontSize.10xl');
    line-height: theme('lineHeight.leading10xl');
    font-weight: theme('fontWeight.thin');
    opacity: 0;
  }

  
  @screen md {
    .b2b-trailing-text-container > p {
      font-size: theme('fontSize.13xl');
      line-height: theme('lineHeight.leading13xl');
    }
    @media (min-height: 500px) {
      .b2b-trailing-text-container > p {
        font-size: 550px;
        line-height: 500px;
      }
    }
    @media (min-height: 800px) {
      .b2b-trailing-text-container > p {
        font-size: 700px;
        line-height: 650px;
      }
    }
    @media (min-height: 1200px) {
      .b2b-trailing-text-container > p {
        font-size: 1100px;
        line-height: 1000px;
      }
    }
  }
  
  .b2b-trailing-text-container > p:nth-child(odd) {
    transform: translateX(25%);
    animation: fade1st 2.5s ease-in-out, moveX 2.5s ease-in-out;
  }
  
  .b2b-trailing-text-container > p:nth-child(even) {
    transform: translateX(-25%);
    animation: fade1st 2.5s ease-in-out .5s, moveX 2.5s ease-in-out .5s;
  }
  
  @keyframes fade1st {
  
    30% {
      opacity: 1;
    }
  
    90% {
      opacity: 0;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  @keyframes moveX {
    100% {
      transform: translateX(0%);
    }
  }

  .b2b-welcome-header {
    opacity: 1;
    animation: showHeader 6s ease-in-out;
  }

  @keyframes showHeader {
    0% {
      opacity: 0;
    }

    75% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  
  .b2b-welcome-text {
    text-shadow: 0 0 4px #fff,0 0 8px #fff;
    opacity: 1;
    animation: showWelcome 10s ease-in-out;
  }

  @keyframes showWelcome {
    0% {
      opacity: 0;
    }

    75% {
      opacity: 0;
    }

    76% {
      opacity: 1;
    }

    77% {
      opacity: 0;
    }

    78% {
      opacity: 1;
    }

    79% {
      opacity: 0;
    }

    80% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }
  
  .b2b-who-are-we {
    font-family: theme('fontFamily.octosquares');
    opacity: 1;
    animation: showWho 7.5s ease-in-out;
  }

  @keyframes showWho {
    0% {
      opacity: 0;
    }

    94% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .b2b-launching-soon {
    opacity: 1;
    animation: showLaunching 10s ease-in-out;
    text-shadow: 0 0 5px theme('colors.b2b-gold'),0 0 10px theme('colors.b2b-gold');
  }

  @keyframes showLaunching {
    0% {
      opacity: 0;
    }

    60% {
      opacity: 0;
    }

    61% {
      opacity: 1;
    }

    62% {
      opacity: 0;
    }

    63% {
      opacity: 1;
    }

    64% {
      opacity: 0;
    }

    63% {
      opacity: 1;
    }

    64% {
      opacity: 0;
    }

    80% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  .b2b-copyright {
    opacity: 1;
    animation: showCopyright 6.5s ease-in-out;
  }

  @keyframes showCopyright {
    0% {
      opacity: 0;
    }

    85% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  
  * {
    font-family: theme('fontFamily.bebas');
  }

  .b2b-button {
    @apply 
      whitespace-nowrap 
      inline-flex 
      items-center 
      justify-center 
      px-4 
      py-2 
      pl-10
      border 
      border-transparent 
      rounded-full 
      shadow-sm 
      text-white
      text-xl 
      bg-b2b-gold 
      opacity-75 
      hover:opacity-100 
      underline 
      decoration-2 
      underline-offset-4;
  }

  .b2b-button[data-loading="true"] {
    @apply !pr-0;
  }

  .b2b-button:hover {
    text-shadow: 0 0 3px #fff,0 0 6px #fff;
  }

  .b2b-button-arrow {
    @apply 
      ml-4 
      h-10
      w-10
      text-gray-500
      inline-block
      mix-blend-multiply;
  }

  .b2b-button-spin {
    @apply 
      !mx-4
      h-10
      w-10
      text-gray-500
      inline-block;
  }

  .b2b-logo {
    @apply text-white text-4xl mr-8 md:mr-6 animate-pulse;
    text-shadow: 0 0 3px #fff,0 0 6px #fff;
  }

  .b2b-nav-link {
    @apply text-b2b-gold ml-2 mr-2 text-2xl md:ml-1 md:mr-3 md:text-xl lg:mr-8;
    transition: text-shadow 250ms ease-in-out;
  }

  .b2b-popup-link {
    @apply text-b2b-gold text-2xl text-center py-2 rounded-md;
    transition: all 250ms ease-in-out;
  }

  .b2b-nav-link:hover,
  .b2b-popup-link:hover {
    @apply 
      underline 
      decoration-2 
      underline-offset-4;
    text-shadow: 0 0 3px theme('colors.b2b-gold'),0 0 6px theme('colors.b2b-gold');
  }

  .b2b-popup-link:hover {
    @apply bg-white;
  }

  .b2b-home-last {
    background: theme('colors.b2b-blue.lighter');
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), transparent);
  }

  .b2b-floating-pass {
    animation: floating 5s ease-in-out infinite;
  }

  @keyframes floating {
    25% {
      transform: rotate3d(2, -1, -1, -0.2turn);
    }

    50% {
      transform: rotate3d(1, 1, 1, 45deg);
    }

    75% {
      transform: rotate3d(0, 1, 0.5, 3.142rad);
    }

    100% {
      transform: rotate3d(0);
    }
  }

  .b2b-beta-btn {
    @apply 
      bg-b2b-gold 
      rounded-full 
      fixed 
      right-8 
      bottom-8
      p-2
      z-[999]
      opacity-100;
    animation: showBetaBtn 6s ease-in-out;
  }

  @keyframes showBetaBtn {
    0% {
      opacity: 0;
    }

    83% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  
  .b2b-beta-btn::before {
    @apply mx-2;
    content: '?';
  }

  .b2b-beta-btn:hover::before {
    content: 'Test Website';
  }

  .b2b-beta-btn:hover[data-testing="true"]::before {
    content: 'End Testing';
  }
}